import { render, staticRenderFns } from "./c-product-product-detail.vue?vue&type=template&id=d111f630&scoped=true&"
import script from "./c-product-product-detail.vue?vue&type=script&lang=js&"
export * from "./c-product-product-detail.vue?vue&type=script&lang=js&"
import style0 from "./c-product-product-detail.vue?vue&type=style&index=0&id=d111f630&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d111f630",
  null
  
)

export default component.exports