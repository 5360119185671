<template>
<div class="p-product">
  <c-swiper :banner="banner"></c-swiper>
  <div class="p-product__detail container-fluid">
    <c-product-product-detail 
    v-for="(item, index) in detailArr.slice(0, 4)"
    :data="item"
    :key="item.title + index"
    :isNormal="index % 2">
    </c-product-product-detail>
  </div>
  <div class="p-product__mlns container-fluid" :style="{'background-image': `url(${mlns.bg})`}">
    <div class="p-product__mlns__container container">
      <div class="p-product__mlns__title">
        {{mlns.title}}
      </div>
      <div class="p-product__mlns__subtitle">
        {{mlns.subTitle}}
      </div>
      <div class="p-product__mlns__text">
        <div 
        class="p-product__mlns__text__item" 
        v-for="(item, index) in mlns.text" 
        :key="item + index">{{item}}</div>
      </div>
      <div class="p-product__mlns__link">
        {{mlns.link && mlns.link.name}}
      </div>
    </div>
  </div>
  <div class="p-product__mall container">
    <div class="p-product__mall__title">{{mall.title}}</div>
    <div class="p-product__mall__icon">
      <div 
      class="p-product__mall__icon__item "
      v-for="(item, index) in mall.icon">
        <img :src="item.src" alt="">
      </div>
    </div>
    <div class="p-product__mall__subtitle">{{mall.subTitle}}</div>
    <div class="p-product__mall__text">{{mall.subTitle}}</div>
  </div>
  <c-product-product-detail 
  v-for="(item, index) in detailArr.slice(4)"
  :data="item"
  :key="item.title + index"
  :isNormal="(index + 1) % 2">
  </c-product-product-detail>
</div>
</template>

<script type="text/javascript">
import cProductProductDetail from './components/c-product-product-detail.vue'
import banner from '../../assets/images/product/banner.png'
import detail0 from '../../assets/images/product/detail_0.png'
import detail1 from '../../assets/images/product/detail_1.png'
import detail2 from '../../assets/images/product/detail_2.png'
import detail3 from '../../assets/images/product/detail_3.png'
import detail4 from '../../assets/images/product/detail_4.png'
import mlnsBg from '../../assets/images/product/mlns_bg.png'
import icon0 from '../../assets/images/product/icon_0.png'
import icon1 from '../../assets/images/product/icon_1.png'
import icon2 from '../../assets/images/product/icon_2.png'
import icon3 from '../../assets/images/product/icon_3.png'
import icon4 from '../../assets/images/product/icon_4.png'
import icon5 from '../../assets/images/product/icon_5.png'
import icon6 from '../../assets/images/product/icon_6.png'
import icon7 from '../../assets/images/product/icon_7.png'
export default {
  data() {
    return {
      banner: [
        {src: banner}
      ],
      detailArr: [
        {
          src: detail0, 
          title: '积分云', 
          subTitle: '为用户解决积分兑换程序繁琐或不知道积分的现金价值的痛点', 
          text: [
            '积分云帮助用户快速变现，智能兑换程序操作简单快捷，首页直达各家家银行的积 分兑换板块直接点击兑换'
          ], 
          link: [
            {name: '报单秒审，提现秒到！', src: 'http://jf.loofire.com/'}
          ]
        },
        {
          src: detail1, 
          title: '中和云POS', 
          subTitle: '市面上首款同时支持收款+积分兑换的POS机。', 
          text: [
            '·收款+积分兑换一体POS机，收款、积分一机搞定',
            '·在支持刷卡、扫码、快捷、云闪付等多种收款方式的基础上， 还为广大用户提供刷卡和信用卡积分兑换等服务', 
            '·致力于为广大用户提供更便捷的收付款服务，及更全面的积分 兑换渠道，打造优质的积分生态圈，为中小商户提供极致体验'
          ], 
          link: null
        },
        {
          src: detail2, 
          title: '优粉云', 
          subTitle: 'AI智控引流系统，通过人工智能技术管控多台设备/多个抖音号', 
          text: [
            '全自动完成一键批量圈粉，撩客，群发消息，发布动态，上传视频，助推上热门',
            '搜索筛选海量客户的获客管理系统'
          ], 
          link: [
            {name: '优粉云下载', src: 'http://124.71.111.66/qrcode'}
          ]
        },
        {
          src: detail3, 
          title: '微圈', 
          subTitle: '便捷沟通，展现个性自我', 
          text: [
            '·把握新消息，聊天安全放心。',
            '·随心所欲畅聊，随时随地交流。', 
            '·支持发送语音短信、视频、图片和文字。', 
            '·可以群聊，仅耗少量流量，适合大部分智能手机。'
          ], 
          link: [
            {name: 'Android下载', src: 'http://meeetim.dypho.com/weiquan1.2.3.apk'},
            {name: 'ios下载', src: 'https://itunes.apple.com/cn/app/qq/id1513072811'}
          ]
        },
        {
          src: detail4, 
          title: '龙火钱包', 
          subTitle: '支持多种钱包形态，多样运营模式', 
          text: [
            '结合微信支付、支付宝支付、第三方银行支付组成一个支付系统',
          ], 
          link: []
        }
      ],
      mlns: {
        bg: mlnsBg,
        title: '玛丽娜丝',
        subTitle: '一款经典怀旧的策略回合制游戏，还原经典设置，20年经典再现！',
        text: [
          '画风Q萌经典，炫酷装备，操作简单易上手。',
          '史前巨兽，部落建设，领土冲突，远古Boss，等你来战！'
        ],
        link: [
          {name: '立即下载>>',src: ''}
        ]
      },
      mall: {
        title: '微圈商城',
        subTitle: '多个平台，一键开店，统一管理',
        icon: [
          {src: icon0},
          {src: icon1},
          {src: icon2},
          {src: icon3},
          {src: icon4},
          {src: icon5},
          {src: icon6},
          {src: icon7}
        ],
        text: '全方位引流，数十种装修组件，上百套行业模板，满足不同行业搭建需求，帮助商家快速搭建属于自己的个性化独立商城'
      }
    }
  },
  created(){

  },
  mounted() {

  },
  components: {
    cProductProductDetail
  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.p-product {
  &__mlns {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 560px;
    &__container {
      padding-top: 228px;
      color: $color1;
    }
    &__title {
      font-size: $fs-title-m;
      font-weight: bold;
      margin-bottom: 30px;
    }
    &__subtitle {
      font-size: $fs-title-xs;
      margin-bottom: 20px;
    }
    &__text {
      font-size: $fs-text-m;
      &__item {
        margin-bottom: 20px;
      }
      margin-bottom: 50px;
    }
    &__link {
      font-size: $fs-text-m;
      text-decoration: underline;
      color: #1FA0F2;
    }
  }
  &__mall {
    padding: 60px 0px;
    &__title {
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 80px;
    }
    &__subtitle {
      font-size: 20px;
      color: #666666;
      margin-bottom: 20px;
    }
    &__text {
      font-size: 16px;
      color: #999999;
    }
    &__icon {
      max-width: 760px;
      margin: 0px auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
  }
}
</style>