<template>
<div class="c-product-product-detail need-ani"  :style="{'background-color': isNormal ? '#EDF0F5' : '#FFFFFF'}">
  <div class="container">
    <div class="row">
      <div 
      class="c-product-product-detail__normal clearfix" 
      v-if="!isNormal"
      :style="{'padding': isPadding ? '0px' : `0px ${mPadding}px`}">
        <div 
        class="c-product-product-detail-left col-xs-12 col-sm-6 col-md-6 col-lg-6 left-displacement" 
        :style="{'justify-content': isPadding ? 'center' : 'flex-start'}">
          <div class="c-product-product-detail__block" v-if="isPadding">
            <img :src="data.src" alt="">
          </div>
          <div class="c-product-product-detail__wrapper" 
          :style="{
            position: isPadding ? 'absolute' : 'relative',
            left: isIE ? '0px' : '',
            transform: !isPadding ? 'translateY(0px)' : 'translateY(-50%)'
          }">
            <div class="c-product-product-detail__title">
              {{data.title}}
            </div>
            <div class="c-product-product-detail__subtitle">
              {{data.subTitle}}
            </div>
            <div class="c-product-product-detail__text">
              <div 
              class="c-product-product-detail__text__item"
              v-for="(item, index) in data.text"
              :key="item + index">
                {{item}}
              </div>
            </div>
            <div class="c-product-product-detail__link">
              <span 
              v-for="(item, index) in data.link" 
              :key="item.name + index"
              @click="bindGoLink(item.src)"
              style="cursor: pointer;">
                <span style="color: #000000; margin: 0px 3px;" v-if="index > 0">/</span>
                {{item.name}}
              </span>
            </div>
          </div>
        </div>
        <div 
        class="c-product-product-detail-right hidden-xs col-sm-6 col-md-6 col-lg-6 right-displacement"
        :style="{'justify-content': isPadding ? 'center' : 'flex-start'}">
          <img :src="data.src" alt="">
        </div>
      </div>
      <div 
      class="c-product-product-detail__reverse clearfix" 
      v-else
      :style="{'padding': isPadding ? '0px' : `0px ${mPadding}px`}">
        <div 
        class="c-product-product-detail-left hidden-xs col-sm-6 col-md-6 col-lg-6 left-displacement"
        :style="{'justify-content': isPadding ? 'center' : 'flex-start'}">
          <img :src="data.src" alt="">
        </div>
        <div 
        class="c-product-product-detail-right col-xs-12 col-sm-6 col-md-6 col-lg-6 right-displacement"
        :style="{'justify-content': isPadding ? 'center' : 'flex-start'}">
          <div class="c-product-product-detail__block" v-if="isPadding">
            <img :src="data.src" alt="">
          </div>
          <div class="c-product-product-detail__wrapper" 
          :style="{
            position: isPadding ? 'absolute' : 'relative',
            left: isIE ? '0px' : '',
            transform: !isPadding ? 'translateY(0px)' : 'translateY(-50%)'
          }">
            <div class="c-product-product-detail__title">
              {{data.title}}
            </div>
            <div class="c-product-product-detail__subtitle">
              {{data.subTitle}}
            </div>
            <div class="c-product-product-detail__text">
              <div 
              class="c-product-product-detail__text__item"
              v-for="(item, index) in data.text"
              :key="item + index">
                {{item}}
              </div>
            </div>
            <div class="c-product-product-detail__link">
              <span 
              v-for="(item, index) in data.link" 
              :key="item.name + index"
              @click="bindGoLink(item.src)"
              style="cursor: pointer;">
                <span style="color: #000000; margin: 0px 3px;" v-if="index > 0">/</span>
                {{item.name}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script type="text/javascript">
import {getViewPort} from '../../../utils/index.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isNormal: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isPadding: true
    }
  },
  created(){
    this.setPaddingByWindowSize();
    window.addEventListener('resize', this.setPaddingByWindowSize);
  },
  mounted() {

  },
  methods: {
    setPaddingByWindowSize() {
      let width = getViewPort().width;
      if(width <= 970) {
        this.isPadding = false;
      } else {
        this.isPadding = true;
      }
    },
    bindGoLink(src) {
      console.log(src);
      src && window.open(src, '_blank'); 
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.setPaddingByWindowSize);
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.c-product-product-detail {
  padding: 85px 0px;
  box-sizing: border-box;
  &__wrapper {
    position: absolute;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
  }
  &__block {
    visibility: hidden;
  }
  &__title {
    color: $color2;
    margin-bottom: 30px;
    font-size: $fs-title-m;
    font-weight: bold;
  }
  &__subtitle {
    font-size: $fs-title-xs;
    color: $color3;
    margin-bottom: 20px;
    max-width: 500px;
  }
  &__text {
    max-width: 500px;
    font-size: $fs-text-m;
    color: $color4;
    line-height: 24px;
    &__item {
      margin-bottom: 20px;
    }
  }
  &__link {
    font-size: 20px;
    color: #1FA0F2;
    line-height: 24px;
  }
  &__normal {
    position: relative;
    .c-product-product-detail {
      &-left {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
    }
  }
  &__reverse {
    .c-product-product-detail {
      &-left {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
    }
  }
}
</style>