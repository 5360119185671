var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-product-product-detail need-ani",style:({'background-color': _vm.isNormal ? '#EDF0F5' : '#FFFFFF'})},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(!_vm.isNormal)?_c('div',{staticClass:"c-product-product-detail__normal clearfix",style:({'padding': _vm.isPadding ? '0px' : ("0px " + _vm.mPadding + "px")})},[_c('div',{staticClass:"c-product-product-detail-left col-xs-12 col-sm-6 col-md-6 col-lg-6 left-displacement",style:({'justify-content': _vm.isPadding ? 'center' : 'flex-start'})},[(_vm.isPadding)?_c('div',{staticClass:"c-product-product-detail__block"},[_c('img',{attrs:{"src":_vm.data.src,"alt":""}})]):_vm._e(),_c('div',{staticClass:"c-product-product-detail__wrapper",style:({
            position: _vm.isPadding ? 'absolute' : 'relative',
            left: _vm.isIE ? '0px' : '',
            transform: !_vm.isPadding ? 'translateY(0px)' : 'translateY(-50%)'
          })},[_c('div',{staticClass:"c-product-product-detail__title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"c-product-product-detail__subtitle"},[_vm._v(" "+_vm._s(_vm.data.subTitle)+" ")]),_c('div',{staticClass:"c-product-product-detail__text"},_vm._l((_vm.data.text),function(item,index){return _c('div',{key:item + index,staticClass:"c-product-product-detail__text__item"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"c-product-product-detail__link"},_vm._l((_vm.data.link),function(item,index){return _c('span',{key:item.name + index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.bindGoLink(item.src)}}},[(index > 0)?_c('span',{staticStyle:{"color":"#000000","margin":"0px 3px"}},[_vm._v("/")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")])}),0)])]),_c('div',{staticClass:"c-product-product-detail-right hidden-xs col-sm-6 col-md-6 col-lg-6 right-displacement",style:({'justify-content': _vm.isPadding ? 'center' : 'flex-start'})},[_c('img',{attrs:{"src":_vm.data.src,"alt":""}})])]):_c('div',{staticClass:"c-product-product-detail__reverse clearfix",style:({'padding': _vm.isPadding ? '0px' : ("0px " + _vm.mPadding + "px")})},[_c('div',{staticClass:"c-product-product-detail-left hidden-xs col-sm-6 col-md-6 col-lg-6 left-displacement",style:({'justify-content': _vm.isPadding ? 'center' : 'flex-start'})},[_c('img',{attrs:{"src":_vm.data.src,"alt":""}})]),_c('div',{staticClass:"c-product-product-detail-right col-xs-12 col-sm-6 col-md-6 col-lg-6 right-displacement",style:({'justify-content': _vm.isPadding ? 'center' : 'flex-start'})},[(_vm.isPadding)?_c('div',{staticClass:"c-product-product-detail__block"},[_c('img',{attrs:{"src":_vm.data.src,"alt":""}})]):_vm._e(),_c('div',{staticClass:"c-product-product-detail__wrapper",style:({
            position: _vm.isPadding ? 'absolute' : 'relative',
            left: _vm.isIE ? '0px' : '',
            transform: !_vm.isPadding ? 'translateY(0px)' : 'translateY(-50%)'
          })},[_c('div',{staticClass:"c-product-product-detail__title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"c-product-product-detail__subtitle"},[_vm._v(" "+_vm._s(_vm.data.subTitle)+" ")]),_c('div',{staticClass:"c-product-product-detail__text"},_vm._l((_vm.data.text),function(item,index){return _c('div',{key:item + index,staticClass:"c-product-product-detail__text__item"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"c-product-product-detail__link"},_vm._l((_vm.data.link),function(item,index){return _c('span',{key:item.name + index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.bindGoLink(item.src)}}},[(index > 0)?_c('span',{staticStyle:{"color":"#000000","margin":"0px 3px"}},[_vm._v("/")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }